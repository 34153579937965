
import { Component, Vue} from "vue-property-decorator";

@Component({
  name: "NotFound",
  components: {
  },
})
export default class NotFound extends Vue{

 
  
}
