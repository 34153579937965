
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "MobileHeader",
})
export default class MobileHeader extends Vue {

  
}
