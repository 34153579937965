import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyDuAONF37SEevIgsBpzGuJKfhoczS_ami8",
    authDomain: "africa-website.firebaseapp.com",
    databaseURL: "https://africa-website-default-rtdb.firebaseio.com",
    projectId: "africa-website",
    storageBucket: "africa-website.appspot.com",
    messagingSenderId: "12051506665",
    appId: "1:12051506665:web:661022d13e16d3c2a61778",
    measurementId: "G-1HJ6HCSJYK"
  };

const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics: Analytics = getAnalytics(firebaseApp);

export default analytics;