
import { Component, Mixins } from "vue-property-decorator";
import { pageNamesMixin } from "@/mixins/pageNamesMixin";

@Component({
  name: "TheHeader",
})
export default class TheHeader extends Mixins(pageNamesMixin) {
  pageNameClicked(recPageName: string): void {
    if (this.$route.name !== recPageName) {
      this.$router.push({ name: recPageName });
    }
  }
}
