import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","elevate-on-scroll":"","clipped-left":""}},[(!_vm.$vuetify.breakpoint.mobile)?_c('the-header'):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.navDrawer = !_vm.navDrawer}}}):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('mobile-header'):_vm._e()],1),_c(VNavigationDrawer,{attrs:{"app":"","clipped":"","mobile-breakpoint":0},model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}},[_c(VList,{attrs:{"nav":""}},[_c(VListItemGroup,_vm._l((_vm.pageNames),function(pageName,i){return _c(VListItem,{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.pageNameClicked(pageName)}}},[_c(VListItemTitle,[_vm._v(_vm._s(pageName))])],1)}),1)],1)],1),_c(VMain,[_c('div',{attrs:{"id":"appMainArea"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"horizontalSpacer"}):_vm._e(),_c('div',{attrs:{"id":"appContentArea"}},[_c('router-view')],1),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"horizontalSpacer"}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }