
import { Component, Mixins} from "vue-property-decorator";
import TheHeader from "@/components/global/MainHeader.vue";
import MobileHeader from "@/components/global/MobileHeader.vue";
import {pageNamesMixin} from "@/mixins/pageNamesMixin";
import analytics from "./store/firebase";
import { logEvent } from "firebase/analytics";

@Component({
  name: "App",
  components: {
    TheHeader,
    MobileHeader,
  },
})
export default class App extends Mixins(pageNamesMixin) {
  navDrawer = false;
  mounted(): void{
    document.title="Uganda Water for Life"
    logEvent(analytics, 'app_mounted');    

  }

  

  pageNameClicked(recPageName: string): void{
    this.navDrawer = false;
    
    if (this.$route.name !== recPageName) {
      this.$router.push({ name: recPageName });
    }
  }

  /*
  run local server: npm run serve
  build for produciton: npm run build
  === need to do this from cmd now ===
  deploy to firebase: firebase deploy --only hosting
  deploy to a channel: firebase hosting:channel:deploy CHANNEL_NAME
  */
  
}
