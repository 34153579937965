import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import About from '@/pages/About.vue'
import BuildingAWell from '@/pages/BuildingAWell.vue'
/*import PrevProject from '@/pages/PrevProject.vue'*/
import Donate from '@/pages/Donate.vue'
import Pastafest from '@/pages/Pastafest.vue'
import NotFound from '@/pages/NotFound.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'About',
    component: About
  },
  {
    path: '/BuildingAWell',
    name: 'Building a Well',
    component: BuildingAWell
  },
  /*
  {
    path: '/2022Project',
    name: '2022 Project',
    component: PrevProject
  },
  */
  {
    path: '/Donate',
    name: 'Donate',
    component: Donate
  },
  {
    path: '/Pastafest',
    name: 'Pastafest',
    component: Pastafest
  },
  {
    path: '/:notFound(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
