
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Pastafest",
  components: {},
})
export default class Pastafest extends Vue {
  mounted(): void {
    var exampleCallback = function () {
      console.log("Order complete!");
    };

    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const EBWidgets: any = window["EBWidgets"];

    EBWidgets.createWidget({
      // Required
      widgetType: "checkout",
      eventId: "911082842307",
      iframeContainerId: "eventbrite-widget-container-911082842307",

      // Optional
      iframeContainerHeight: 750, // Widget height in pixels. Defaults to a minimum of 425px if not provided
      onOrderComplete: exampleCallback, // Method called when an order has successfully completed
    });
  }
}
